/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../bootstrap';
import '../plugins';
import Vue from 'vue';

window.Vue = Vue;
window.proj4 = require('proj4').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('country-map', require('./components/CountryMap.vue').default);
Vue.component('state-map', require('./components/StateMap.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});



$(document).ready(function () {
    $("#alto-contraste").click(function () {
        if (!$('body').hasClass("contrast-active")) {
            $('body').addClass("contrast-active");
        } else {
            $('body').removeClass('contrast-active');
        }
    });

    $(document).on('keypress', function (e) {
        if (e.which == 161) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".conteudo").offset().top
            }, 200);
        } else if (e.which == 8482) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".content-header").offset().top
            }, 200);
        } else if (e.which == 163) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".content-header").offset().top
            }, 200);

            setTimeout(function () {
                $('[name="pesquisa"]').focus();
            }, 250);
        }
    });
});
