var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shown
    ? _c(
        "remote-data",
        { attrs: { requests: _vm.requests }, on: { completed: _vm.build } },
        [
          _c("div", { ref: "map", class: _vm.classes.map }),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.classes.aside },
            [
              Object.keys(_vm.selected).length > 0
                ? _vm._t("selected", null, { selected: _vm.selected })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("legend")
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }