<template>
  <div>
    <slot :responses="responses" :completed="completed"></slot>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RemoteData",
  props: {
    requests: { type: Object }
  },
  data() {
    return {
      responses: Object.assign(
        ...Object.keys(this.requests).map(key => ({ [key]: null }))
      ),
      completed: false
    };
  },
  mounted() {
    axios.all(Object.values(this.requests)).then(
      axios.spread((...responses) => {
        Object.keys(this.requests).forEach((value, index) => {
          this.responses[value] = responses[index];
        });

        this.completed = true;

        this.$emit("completed", this.responses);
      })
    );
  }
};
</script>
