
<template>
  <remote-data :requests="requests" @completed="build" v-if="shown">
    <div ref="map" :class="classes.map"></div>
    <div :class="classes.aside">
      <slot name="selected" v-if="Object.keys(selected).length > 0" :selected="selected"></slot>
      <slot name="legend"></slot>
    </div>
  </remote-data>
</template>

<script>
import axios from "axios";
import groupBy from "lodash/groupBy";
import Highcharts from "highcharts/highmaps";

import RemoteData from "./RemoteData";

export default {
  name: "CountryMap",
  components: { RemoteData },
  props: {
    classes: {
      map: { type: String, default: "" },
      aside: { type: String, default: "" }
    }
  },
  data() {
    return {
      requests: {
        data: axios.get("/municipalities"),
        map: axios.get("/js/mapa/all.json")
      },
      selected: {},
      shown: false
    };
  },
  mounted() {
    new IntersectionObserver(([entry], observer) => {
      if (entry.intersectionRatio > 0) {
        this.shown = true;

        observer.unobserve(entry.target);
      }
    }).observe(this.$el.parentNode);
  },
  methods: {
    build(responses) {
      const self = this;

      const colors = {
        1: "#DC4B93",
        2: "#5C931B",
        3: "#106680",
        4: "#A33071",
        5: "#E32D09"
      };

      Highcharts.mapChart(this.$refs["map"], {
        chart: {
          map: responses.map.data,
          backgroundColor: null,
          height: "100%"
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver() {
                  self.$set(self, "selected", this.options);
                },
                mouseOut() {
                  self.$set(self, "selected", {});
                },
                select() {
                  window.location.href = `/mapa/${this.options.code.toLowerCase()}`;
                }
              }
            },
            states: {
              select: {
                color: "#c1b098",
                animation: { duration: 0 }
              },
              hover: {
                animation: { duration: 0 }
              }
            }
          },
          map: {
            nullColor: "#C8B9A4",
            borderColor: "white",
            states: {
              select: {
                color: "#2b8b9c",
                animation: false
              },
              hover: {
                animation: false
              }
            }
          }
        },
        credits: false,
        title: { text: "" },
        tooltip: { enabled: false },
        series: [
          {
            color: "#C8B9A4",
            joinBy: ["hc-a2", "code"],
            data: Object.entries(groupBy(responses.data.data, "federative_unit.code")).map(
              ([key, values]) => ({
                code: key,
                name: values.map(v => v.federative_unit.name)[0],
                count: values.length,
                area: values
                  .map(v => v.area)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("pt-BR", { maximumFractionDigits: 2 }),
                population: values
                  .map(v => v.population)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("pt-BR"),
                number_ace: values
                  .map(v => v.number_ace)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("pt-BR"),
                number_acs: values
                  .map(v => v.number_acs)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("pt-BR"),
                number_teams: values
                  .map(v => v.number_teams)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("pt-BR")
              })
            ),
            showInLegend: false,
            cursor: "pointer",
            allowPointSelect: true
          },
          ...Object.entries(colors).map(([key, color]) => ({
            type: "mappoint",
            name: `Grupo ${key}`,
            color: color,
            stickyTracking: false,
            dataLabels: { enabled: false },
            marker: { symbol: "circle", radius: 2 },
            states: {
              hover: { enabled: false }
            },
            data: responses.data.data
              .filter(m => m.group === +key)
              .map(m => ({
                name: m.name,
                lat: +m.latitude,
                lon: +m.longitude
              }))
          }))
        ]
      });
    }
  }
};
</script>

<style scoped>
</style>
