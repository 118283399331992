<template>
  <remote-data :requests="requests" @completed="build">
    <div ref="map"></div>
  </remote-data>
</template>

<script>
import axios from "axios";
import Highcharts from "highcharts/highmaps";

import RemoteData from "./RemoteData";

export default {
  name: "StateMap",
  components: { RemoteData },
  props: {
    code: { type: String }
  },
  data() {
    return {
      requests: {
        data: axios.get("/municipalities"),
        map: axios.get(`/js/mapa/geos/${this.code.toLowerCase()}.json`)
      }
    };
  },
  methods: {
    build(responses) {
      const colors = {
        1: "#DC4B93",
        2: "#5C931B",
        3: "#106680",
        4: "#A33071",
        5: "#E32D09"
      };

      Highcharts.mapChart(this.$refs["map"], {
        chart: {
          map: responses.map.data,
          backgroundColor: null
        },
        legend: { enabled: false },
        credits: false,
        title: { text: "" },
        tooltip: {
          enabled: true,
          style: { fontFamily: "Lato, sans-serif" },
          formatter() {
            return this.key;
          }
        },
        series: [
          {
            data: [],
            showInLegend: false
          },
          ...Object.entries(colors).map(([key, color]) => ({
            id: `group-${key}`,
            name: `Grupo ${key}`,
            allAreas: false,
            color: color,
            stickyTracking: false,
            dataLabels: { enabled: false },
            states: { hover: { enabled: false } },
            joinBy: ["id", "code"],
            data: responses.data.data
              .filter(m => m.group === +key)
              .filter(m => m.federative_unit.code === this.code.toUpperCase())
              .map(m => ({
                code: m.code,
                name: m.name
              }))
          }))
        ]
      });
    }
  }
};
</script>

<style scoped>
</style>
